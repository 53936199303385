import styled from "styled-components";
import openBackground from './img/logo-babka.png'

export const StyledShutter = styled.div`
    padding-top: 100%;
    cursor: pointer;
    position: relative;

    .front {
        background: center / cover url(${props => props.background});
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        -webkit-transform-style: preserve-3d;
        -webkit-perspective: 550px;
        -webkit-transform-origin: 50% 0;
        -webkit-transition: all 0.5s;
        -moz-transform-style: preserve-3d;
        -moz-perspective: 550px;
        -moz-transform-origin: 50% 0;
        -moz-transition: all 0.5s;
        -o-transform-style: preserve-3d;
        -o-perspective: 550px;
        -o-transform-origin: 50% 0;
        -o-transition: all 0.5s;
        transform-style: preserve-3d;
        perspective: 550px;
        transform-origin: 50% 0;
        transition: all 0.5s;

        :hover {
            transform: scale(1,0.95) skew(3deg,0deg);
        }
    
        p {
            display: flex;
            justify-content: center;
            align-items: center;
            /* font-family: 'Chewy'; */
            color: #000000;
            padding: 20px;
            width: 10%;
            height: 10%;
            border-radius: 50%;
            background-color: rgba(255 , 255 , 255 , 0.7);
            font-weight: 700;
            font-size: 2rem;
        }

        &.open {
            transform: rotateX(90deg);
        }
    }

    .back {
        background: center / contain url(${openBackground}) no-repeat #FBD1D2;
        background-size: 50%;
        padding: 10px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transform: rotateX(180deg);

        &.open{
        z-index: 2;
        transform: rotateX(0deg);
        }

    }

    

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: all 0.5s;
        transform-style: preserve-3d;
        border-radius: 10px;
        box-sizing: border-box;
    }

`