import styled from "styled-components";


export const Container = styled.div`
    max-width: 1200px;
    margin: 30px auto;
    padding: 35px 40px 60px 40px;
    background: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 1px 7px 24px -12px rgba(66, 68, 90, 1);
    -moz-box-shadow: 1px 7px 24px -12px rgba(66, 68, 90, 1);
    box-shadow: 1px 7px 24px -12px rgba(66, 68, 90, 1);
    position: relative;
`
