import styled from "styled-components";
import gifts from './img/gifts.svg'

export const GiftBox = styled.div`
    background: url(${gifts}) no-repeat;
    background-size: contain;
    background-position-x: center;
    position: absolute;
    bottom: -150px;
    width: 90%;
    height: 200px;

    @media screen and (max-width: 700px) {
        visibility: hidden;
    }
`

