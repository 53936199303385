import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { createPortal } from "react-dom";
import "./modal-styles.css";
import { warningsArray } from "./helpers";
import { ImageForShutter } from "./ImageEmbed";
import deer from './img/deer.svg'


const modalElement = document.getElementById("modal-root");

export function Modal(
  { children, fade = false, defaultOpened = false, id, shutterData, day },
  ref
) {
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const close = useCallback(() => setIsOpen(false), []);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close,
    }),
    [close]
  );

  const handleEscape = useCallback((event) => {
    if (event.keyCode === 27) setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleEscape, false);
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  const clickedShutterModal = shutterData.find((shutter) => shutter.nr === id);

  let randomWarning =
    warningsArray[Math.floor(Math.random() * warningsArray.length)];

  return createPortal(
    isOpen ? (
      <div className={`modal ${fade ? "modal-fade" : ""}`}>
        <div className="modal-overlay" onClick={close} />
        <div className="modal-body">
          <span
            role="button"
            className="modal-close"
            aria-label="close"
            onClick={close}
          >
            x
          </span>
          <div>
            <span className="modal-date">
            <div className="leaf leaf-left"></div>
              {clickedShutterModal.nr}
            <div className="leaf leaf-right"></div>
            </span>
            
          </div>
          {clickedShutterModal.nr <= day ? (
            <div className="modal-container">
              {clickedShutterModal.title && (<h1 className="modal-title">{clickedShutterModal.title}</h1>)}
              {clickedShutterModal.picture && (
                <ImageForShutter src={clickedShutterModal.picture} />
              )}
              <div className="modal-description">
                {clickedShutterModal.text}
              </div>
              {clickedShutterModal.source && (<span className="modal-source">źródło: {clickedShutterModal.source}</span>)}
            </div>
          ) : (
            <>
            <div className="modal-description">{randomWarning}</div>
            <img className="deer" src={deer} alt='deer' />
            </>
          )}
        </div>
      </div>
    ) : null,
    modalElement
  );
}

export default forwardRef(Modal);
