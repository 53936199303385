import React from "react";
import { StyledShutter } from "./ShutterStyles";

const Shutter = ({ shutterData: { id, nr, text, img, open }, handleClick }) => (
  <StyledShutter background={img} onClick={() => handleClick(id)}>
    <div className={open ? "front open" : "front"}>
      <p>{nr}</p>
    </div>
    <div className={open ? "back open" : "back"}>
      <div className="bulb"></div>
    </div>
  </StyledShutter>
);

export default Shutter;
