import React from "react";
import { StyledHeader } from "./HeaderStyles";

const Header = ({counter}) => (
    <StyledHeader>
        <h1>Witaj w kalendarzu adwentowym dla deweloperów mieszkaniowych</h1>
        <a href="https://obido.pl/">
            <div className="logo" />    
        </a>
        <span className="progress-bar__description">
            Otwarte {counter} z 24.
        </span>
        <progress id="progress-bar" max="24" value={counter} />
    </StyledHeader>
    
)

export default Header;