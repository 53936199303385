import styled from "styled-components";

export const StyledApp = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));

  @media screen and (max-width: 700px) {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
`;
