const shuffle = arr => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};


export const chocolateArray = [
  {
    id: "door-1",
    nr: 1,
    img: "./img/1.jpg",
    title: "",
    source: "obido",
    text:
      "69,9% szukających mieszkania szuka lokalu 2- lub 3-pokojowego, wartość ta powoli spada. W 2017 r. było to 77-78% (zagregowane dane dla Krakowa, Trójmiasta, Warszawy i Wrocławia)",
    open: false,
    picture: "./img/door-pics/1-chart.svg"
  },
  {
    id: "door-2",
    nr: 2,
    img: "./img/2.jpg",
    title: "",
    source: "",
    text:
      "",
    open: false,
    picture: "./img/door-pics/2-picture.png"
  },
  {
    id: "door-3",
    nr: 3,
    img: "./img/3.jpg",
    title: "10:8",
    source: "Otodom",
    text:
      "W 10 z 18 dzielnic Krakowa średnia cena ofertowa mkw. mieszkania na rynku pierwotnym przekracza 10 tys. zł. Zdecydowanie drożej jest na zachodzie niż wschodzie miasta.",
    open: false,
    picture: ""
  },
  {
    id: "door-4",
    nr: 4,
    img: "./img/4.jpg",
    title: "",
    source: "",
    text:
      "Jako obido, Otodom i OLX mamy 98% danych o popycie i podaży na siedmiu największych rynkach mieszkaniowych w Polsce.",
    open: false,
    picture: "./img/door-pics/4-picture.png"
  },
  {
    id: "door-5",
    nr: 5,
    img: "./img/5.jpg",
    title: "27 mln zł",
    source: "",
    text:
      "Najdroższy dom wystawiony w serwisie Otodom nie leży ani w żadnym z dużych miast, ani w popularnej lokalizacji turystycznej. To wybudowany na początku XX wieku pałac myśliwski prezydenta Mościckiego położony w sercu Krajeńskiego Parku Krajobrazowego. Główny budynek ma ponad 1000 mkw, a działka (a właściwie prywatny park ze starodrzewiem) 6,3 ha. Do tego kompleks rekreacyjny z zewnętrznym jacuzzi, piwnica na wino oraz ogród w stylu francuskim i staw z karpiami japońskimi.",
    open: false,
    picture: ""
  },
  {
    id: "door-6",
    nr: 6,
    img: "./img/6.jpg",
    title: "23%",
    source: "obido",
    text:
      "Rozwiązania smart home są ważne przy wyborze mieszkania dla 23% użytkowników obido.",
    open: false,
    picture: "./img/door-pics/6-chart.svg"
  },
  {
    id: "door-7",
    nr: 7,
    img: "./img/7.jpg",
    title: "165,8 tys.",
    source: "obido na podst. danych GUS",
    text:
      "W ostatnich 12 miesiącach deweloperzy rozpoczęli budowę 165,8 tys. lokali mieszkalnych (o 28% więcej niż w analogicznym okresie rok wcześniej)",
    open: false,
    picture: ""
  },
  {
    id: "door-8",
    nr: 8,
    img: "./img/8.jpg",
    title: "24%",
    source: "obido",
    text:
      "Średnia maksymalna kwota deklarowana przez klientów szukających 1- i 2-pokojowego mieszkania we Wrocławiu wzrosła w ostatnich dwóch latach o ponad 24 proc.",
    open: false,
    picture: ""
  },
  {
    id: "door-9",
    nr: 9,
    img: "./img/9.jpg",
    title: "",
    source: "obido",
    text:
      "Duża ilość zieleni w pobliżu inwestycji jest dla kupujących prawie trzy razy ważniejsza niż smart home i lobby z recepcją razem wzięte.",
    open: false,
    picture: ""
  },
  {
    id: "door-10",
    nr: 10,
    img: "./img/10.jpg",
    title: "Wilda, Sołacz i Stare Miasto",
    source: "Otodom",
    text:
      "Poznańskie osiedla z najwyższymi cenami za mkw. mieszkania na rynku pierwotnym to Wilda, Sołacz i Stare Miasto.",
    open: false,
    picture: ""
  },
  {
    id: "door-11",
    nr: 11,
    img: "./img/11.jpg",
    title: "",
    source: "",
    text:
      "W 2019 i 2020 obido uznane zostało przez Deloitte za jedną z dwudziestu najszybciej rozwijających się spółek technologicznych w naszej części świata.",
    open: false,
    picture: ""
  },
  {
    id: "door-12",
    nr: 12,
    img: "./img/12.jpg",
    title: "1993",
    source: "",
    text:
      "W styczniu 1993 r. GUS zarejestrował pierwsze mieszkania deweloperskie - było ich trzy. W całym 1993 r. deweloperzy oddali do użytkowania 485 lokali mieszkalnych.",
    open: false,
    picture: ""
  },
  {
    id: "door-13",
    nr: 13,
    img: "./img/13.jpg",
    title: "",
    source: "obido",
    text:
      "62% użytkowników obido nie jest zainteresowanych możliwością ładowaniach w garażu podziemnym elektrcznego samochodu jeśli jednorazowo kosztowałoby to przy zakupie mieszkania tysiąc złotych.",
    open: false,
    picture: "./img/door-pics/13-chart.svg"
  },
  {
    id: "door-14",
    nr: 14,
    img: "./img/14.jpg",
    title: "9%",
    source: "GUS",
    text:
      "W Polsce koszty utrzymania mieszkania rosną obecnie szybciej niż inflacja (+9% vs +6,8%).",
    open: false,
    picture: ""
  },
  {
    id: "door-15",
    nr: 15,
    img: "./img/15.jpg",
    title: "",
    source: "Otodom",
    text:
      "Szukający mieszkania w Łodzi, najczęściej szukają go na Widzewie i Bałutach. We wrześniu było to odpowiednio 163 i 151 tys. wyszukiwań w Otodom.",
    open: false,
    picture: ""
  },
  {
    id: "door-16",
    nr: 16,
    img: "./img/16.jpg",
    title: "",
    source: "obido",
    text:
      "Dla 34% klientów rynku deweloperskiego rozwiązania ekologiczne są ważne przy wyborze nieruchomości.\n Ale 45% nie ma zdania na ten temat.",
    open: false,
    picture: ""
  },
  {
    id: "door-17",
    nr: 17,
    img: "./img/17.jpg",
    title: "9x",
    source: "JLL",
    text:
      "Liczba nowych mieszkań dostępnych na warszawskiej Białołęce jest dziewięciokrotnie większa niż na Ochocie, Śródmieściu i Żoliborzu razem wziętych.",
    open: false,
    picture: ""
  },
  {
    id: "door-18",
    nr: 18,
    img: "./img/18.jpg",
    title: "322 mln",
    source: "",
    text:
      "W 2021 r. obido dołączyło do OLX Group. Nazwa OLX pochodzi od \"OnLine Exchange\". OLX Group zatrudnia na świecie ponad 11 tys. osób, korzysta z niego 322 mln użytkowników miesięcznie.",
    open: false,
    picture: ""
  },
  {
    id: "door-19",
    nr: 19,
    img: "./img/19.jpg",
    title: "85%",
    source: "Eurostat",
    text:
      "Ponad 85% Polaków mieszka we własnych mieszkaniach. Dla porównania w Szwajcarii wskaźnik ten przekracza 40%, a w Niemczech i Austrii - 50%.",
    open: false,
    picture: ""
  },
  {
    id: "door-20",
    nr: 20,
    img: "./img/20.jpg",
    title: "3x",
    source: "obido",
    text:
      "Pytani o rozwiązania ekologiczne użytkownicy obido trzy razy częściej wskazują \"utrzymanie istniejącego zadrzewienia\" niż \"system rekuperacji\", \"certyfikat ekologiczny (np. BREEAM)\" czy \"ładowarki do samochodów elektrycznych\".",
    open: false,
    picture: ""
  },
  {
    id: "door-21",
    nr: 21,
    img: "./img/21.jpg",
    title: "-10%",
    source: "GUS",
    text:
      "W okresie styczeń-wrzesień 2021 deweloperzy rozpoczęli w Warszawie budowę 13,8 tys. lokali mieszkalnych, w tym samym czasie uzyskali 12,6 tys. pozwoleń na budowę (o 10% mniej)",
    open: false,
    picture: ""
  },
  {
    id: "door-22",
    nr: 22,
    img: "./img/22.jpg",
    title: "",
    source: "obido",
    text:
      "6,2% szukających mieszkania szuka lokalu większego niż 80 mkw.\n (zagregowane dane dla Krakowa, Trójmiasta, Warszawy i Wrocławia).",
    open: false,
    picture: ""
  },
  {
    id: "door-23",
    nr: 23,
    img: "./img/23.jpg",
    title: "",
    source: "obido",
    text:
      "Dla 13 proc. klientów rynku deweloperskiego nie ma znaczenia fakt, czy deweloper prowadzi działalność z poszanowaniem środowiska naturalnego.",
    open: false,
    picture: ""
  },
  {
    id: "door-24",
    nr: 24,
    img: "./img/24.jpg",
    title: "",
    source: "",
    text:
      "Zdrowych, spokojnych i rodzinnych Świąt Bożego Narodzenia oraz pomyślnego 2022 roku. By dane, które dostarcza obido, były jeszcze lepsze niż w 2021!",
    open: false,
    picture: "./img/door-pics/24-christmas-card.svg"
  },
];

export const createCalendar = () => shuffle(chocolateArray);

export const warningsArray = [
  "Oj, ktoś tu się nie zna na datach.",
  "Chyba nie chcesz mi powiedzieć, że jako dziecko też wyjadałeś czekoladki przedwcześnie?",
  "Cierpliwości. Przyjdzie taki dzień, że i to okienko się otworzy",
  "Ryzykujesz, że Mikołaj się nie zjawi. Lubisz życie na krawędzi, prawda?",
  "Jeszcze raz spróbuj otworzyć złe okienko, a Mikołaj przyjdzie z rózgą zamiast worka prezentów.",
  "Ups! Odnotowaliśmy próbę otwarcia złego okienka! Mikołaj patrzy!",
  "Mikołaj? Gwiazdor? Dziadek Mróz? Każdy z nich widzi, że próbujesz otworzyć okienko za wcześnie."
]