import styled from "styled-components";

export const StyledHeader = styled.div`
    display: flex;
    justify-content: center;
    color: #000;
    flex-direction: column;
    align-items: center;
    font-size: 0.6rem;
    text-align: center;

    h1 {
        margin: 0;
    }

    .logo {
        width: 200px;
        height: 80px;
        background: center / contain url('./img/logo.svg') no-repeat;
        margin: 20px 0;
    }

    progress[value] {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        margin: 10px;
    }

    progress[value]::-webkit-progress-bar {
        background-color: #eee;
        border-radius: 3px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
    }

    progress[value]::-webkit-progress-value {
        background-color: rgba(221, 52, 57, 0.8);
        border-radius: 3px; 
        background-size: 35px 20px, 100% 100%, 100% 100%;
    }

    .progress-bar__description {
        color: #a5a5a5;
        font-size: 0.8rem;
    }
`