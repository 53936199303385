import React, { useState, useEffect, useRef } from "react";
import { createGlobalStyle } from "styled-components";
import { StyledApp } from "./AppStyles";
import { createCalendar } from "./helpers";
import Shutter from "./Shutter";
import Modal from "./Modal";
import Header from "./Header";
import { Container } from "./AppContainer";
import { GiftBox } from "./GiftsBox";

const GlobalStyle = createGlobalStyle`
  body {
    height: 95vh;
    background: center / cover url('./img/tlo.png') no-repeat;
    background-position-y: bottom;
    margin: 0;
    padding: 30px;
    background-color: #EDF2F5;
    font-family: 'Autour One';
  }
`;

function App() {
  const [shutters, setShutters] = useState([]);
  const date = new Date();
  let options = { day: "numeric" };
  const day = new Intl.DateTimeFormat("pl", options).format(date);
  const modal = useRef(null);
  const [openedId, setClickedId] = useState(0);

  const handleOpenShutter = (id) => {
    const updatedShutter = shutters.map((shutter) =>
      shutter.id === id && shutter.nr <= day
        ? { ...shutter, open: !shutter.open, openedDoor: 1 }
        : shutter
    );
    setShutters(updatedShutter);

    const clickedShutter = shutters.find((shutter) => shutter.id === id);

    !clickedShutter.open && modal.current.open();
    setClickedId(clickedShutter.nr);
    
  };

  useEffect(() => {
    const calendar = localStorage.calendar
      ? JSON.parse(localStorage.calendar)
      : createCalendar();

    setShutters(calendar);
  }, []);

  useEffect(() => {
    shutters.length &&
      localStorage.setItem("calendar", JSON.stringify(shutters));
  }, [shutters]);

  const counter = shutters.filter(shutter => shutter.openedDoor === 1).length;

  return (
    <>
      <GlobalStyle />
      <Container>
        <Header counter={counter}/>
        <StyledApp>
          {shutters.map((shutter) => (
            <Shutter
              key={shutter.id}
              shutterData={shutter}
              handleClick={handleOpenShutter}
            />
          ))}
        </StyledApp>
        <GiftBox />
      </Container>
      <Modal ref={modal} shutterData={shutters} id={openedId} day={day}></Modal>
    </>
  );
}

export default App;
